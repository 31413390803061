import { React } from 'react';
import Stack from 'react-bootstrap/Stack';
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import { FaTwitterSquare, FaLinkedin } from 'react-icons/fa'
import WhartonLogo from '../assets/images/logos/wharton-logo-white.png'
import PennLogo from '../assets/images/logos/penn-logo-white.svg'
import { applicationLink, interestLink } from '../utilities/constants';

function Footer() {
  return (
    <div className="footer">
      <div className='flex-container'>
        <div className='flex-child footer-left'>
          <div>
            <div className='footer-logos'>
              <a href='https://www.upenn.edu/' target="_blank" rel="noopener noreferrer"><Image className='footer-penn-logo' src={PennLogo}/></a>
              <a href='https://www.wharton.upenn.edu/' target="_blank" rel="noopener noreferrer"><Image className='footer-wharton-logo' src={WhartonLogo}/></a>
            </div>
            <p className='footer-left-text'>
              © 2024 Cypher Accelerator | <a href='https://drive.google.com/file/d/1g0bb0H0Ek2op6YaAIg-cM2rLHHktdxuy/view?usp=sharing' target="_blank" rel="noopener noreferrer">Disclaimer</a><br/>
              © 2024 <a href='https://www.wharton.upenn.edu/' target="_blank" rel="noopener noreferrer">The Wharton School </a> | 
              <a href='https://www.upenn.edu/' target="_blank" rel="noopener noreferrer"> The University of Pennsylvania </a> | 
              <a href='https://www.upenn.edu/about/privacy-policy/' target="_blank" rel="noopener noreferrer"> Privacy Policy </a> | 
              <a href='https://accessibility.web-resources.upenn.edu/get-help' target="_blank" rel="noopener noreferrer"> Report Accessibility Issues and Get Help</a><br/>
            </p>
          </div>
        </div>
        <div className='footer-stack'>
            <div className='footer-stack-container'>
              <div className='flex-child footer-middle-left'> 
                <div className='footer-subtitle'>
                  JOIN THE COMMUNITY
                </div>
                <div className='footer-links'>
                  <a href={interestLink} target="_blank" rel="noopener noreferrer">Join Us</a>
                  {/* <a href={applicationLink} target="_blank" rel="noopener noreferrer">Apply</a> */}
                </div>
              </div>

              <div className='flex-child footer-middle'> 
                <div className='footer-subtitle'>
                  LEARN MORE
                </div>
                <div className='footer-links'>
                  <Link to="/companies">Companies</Link>
                  <Link to="/team">Team</Link>
                  <Link to="/news">News</Link>
                  <a href='mailto: cypheraccelerator@gmail.com' target="_blank" rel="noopener noreferrer">Contact Us</a>
                  {/* <a href='https://stevenscenter.wharton.upenn.edu/incubator/' target="_blank" rel="noopener noreferrer">Incubator</a> */}
                </div>
              </div>
            </div>         
            <div className='flex-child footer-right'>
              <div className='footer-subtitle'>
                FOLLOW US
              </div>
              <Stack direction="horizontal" gap={1}>
                <a href='https://www.linkedin.com/company/cypher-accelerator' target="_blank" rel="noopener noreferrer"><FaLinkedin size={30}/></a>
                <a href='https://twitter.com/WhartonCypher' target="_blank" rel="noopener noreferrer"><FaTwitterSquare size={30}/></a>
                {/* <a href='' target="_blank" rel="noopener noreferrer"><FaMedium size={30}/></a>
                <a href='https://www.youtube.com/channel/UCV6LarfMcfqyzNBtiz5owaA' target="_blank" rel="noopener noreferrer"><FaYoutubeSquare size={30}/></a> */}
              </Stack>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;