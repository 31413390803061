import React, { useEffect, useState } from 'react';
import {motion} from "framer-motion"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom";

import Marquee from "react-fast-marquee";

import tabletBackgroundImage from "../assets/images/hero-background-images/new_tablet.png"
import desktopBackgroundImage from "../assets/images/hero-background-images/new_desktop.png"
import phoneBackgroundImage from "../assets/images/hero-background-images/new_mobile.png"
import { applicationLink } from '../utilities/constants';
// import { jobLink } from '../utilities/constants';

function Hero(props) {
  // Media Queries
  const isLargerThanSplit = useMediaQuery({ query: '(min-width: 1000px)'});
  const isLargerThanPhone = useMediaQuery({ query: '(min-width: 500px)'});

  // Framer Variants
  const wordVariants = {
    offscreen: {opacity: 0.25},
    onscreen: (i) => {return {opacity: 1, transition: {
        delay: i * 0.5 + wordDelay, duration: 0.5
    }}}
  }
  const wordVariants2 = {
    offscreen: {opacity: 0.25, color: "black"},
    onscreen: (i) => {return {color: "black", opacity: 1, transition: {
        delay: i * 0.5 + wordDelay, duration: 0.5
    }}}
  }

  // Framer Variants Helpers
  let wordDelay = 0.5;
  let counter = -1;
  function increment() {
    counter += 1;
    return counter;
  }

  // Marquee Helpers
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    let difference = +new Date(`11/22/${year}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      };
    }

    return timeLeft;
  }
  const timerComponents = [];

  // State Hooks
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Effect Hooks
  useEffect(() => {
    const imageList = [tabletBackgroundImage, desktopBackgroundImage, phoneBackgroundImage];
    imageList.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    })
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  // Executed Code
  timerComponents.push(
    <span key={'text'}>
      {'Apply before November 22nd.'} {" "}
    </span>
  );
  Object.keys(timeLeft).forEach((interval, index) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span key={index}>
        {timeLeft[interval]} {interval}{""}
      </span>
    );
  });
  timerComponents.push(
    <span key={'text-2'}>
      {'s left!'} {" "}
    </span>
  );
  
  // Render Method
  return (
    <motion.div className="hero" initial="offscreen" whileInView="onscreen" viewport={{ once:true}}>
      <div className="background-image" style={{backgroundImage: isLargerThanSplit ? `url(${desktopBackgroundImage})` : isLargerThanPhone ? `url(${tabletBackgroundImage})` : `url(${phoneBackgroundImage})`}}></div>
      <div className="hero-main-container">
        {/* <div className="section-title-text"><span style={{color: "black", textShadow : '#000 0px 0px 2px'}}><motion.span variants={wordVariants} custom={increment()}>Power Up.</motion.span></span><span style={{color: "black", textShadow : '#fff 0px 0px 2px'}}><motion.span variants={wordVariants2} custom={increment()}> Join<br/>Wharton Cypher.</motion.span></span></div> */}
        <div className="section-title-text"><span style={{color: "#152663"}}><motion.span variants={wordVariants} custom={increment()}>Power Up.</motion.span></span><span style={{color: "black", textShadow : '#fff 0px 0px 2px'}}><motion.span variants={wordVariants2} custom={increment()}> <br/>Join Cypher.</motion.span></span></div>
        <div className="engage-buttons">
          {/* <a href={jobLink} target="_blank" rel="noopener noreferrer"><button className="black-button">JOIN US</button></a> */}
          <a href={applicationLink} target="_blank" rel="noopener noreferrer"><button className="gradient-button">APPLY NOW</button></a>
          {/* <Link to="/companies"><button className="gradient-button">OUR COMPANIES</button></Link> */}
          <Link to="/companies"><button className="black-button">OUR COMPANIES</button></Link>
        </div>
        <div className="hero-description">Cypher is built to power the world's emerging technology companies.</div>
      </div>
      <div className='marquee-section' id="marquee-section" >
        <Marquee gradient={false}>
          {Array(12).fill(timeLeft.day < 60 ? (
            <span className='group' key={'timer'}>
              {timerComponents}
            </span>
          ) : (
            <span className='group' key={'announcement'} style={{fontWeight:700}}>
              Applications Now Open!
            </span>
          ))}
        </Marquee>
      </div>
    </motion.div>
  );
}

export default Hero;