import React from 'react'
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/esm/Container.js';
import { FaBars } from 'react-icons/fa'
import cypherNavbarLogo from '../assets/images/logos/new_wharton_cypher_logo.png'
import stevensCenterLong from '../assets/images/logos/new_stevens_logo.png'
import { applicationLink } from '../utilities/constants';
import { jobLink } from '../utilities/constants';

function Navibar(props) {
  const isLargerThanSplit = useMediaQuery({ query: '(min-width: 750px)'});

  function toggleClass() {
    var navbar = document.getElementById("navbar");
    navbar.classList.toggle('nav-expand');
  }

  return (
    <div>
      <div className='nav-section'>
        {/* <div className={isLargerThanSplit ? 'penn-header' : 'penn-header center-header'}>
          <a href="https://stevenscenter.wharton.upenn.edu/" target="_blank" rel="noopener noreferrer">
            <img src={stevensCenterLong} alt="" height="40px"/>
          </a>
        </div> */}
        {['lg'].map((expand) => (
          <Navbar key={expand} bg="" expand={expand} className={props.secondaryPage ? 'navbar nav-active' : 'navbar'} id='navbar'>  
            <Container fluid>
              <Navbar.Brand><Link to="/"><img src={cypherNavbarLogo} alt='' height="30vh"/></Link></Navbar.Brand>
              <Navbar.Toggle onClick={toggleClass} aria-controls="navbarScroll"> 
                <FaBars/>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="navbar-links-and-buttons justify-content-end flex-grow-1 pe-3"
                  style={{ maxHeight: '300px' }}
                  navbarScroll
                >
                  <Link to="/team">Team</Link>
                  <Link to="/companies">Companies</Link>
                  <Link to="/news" >Thought Leadership</Link>
                  {/* <a href={jobLink} target="_blank" rel="noopener noreferrer">Join Us</a> */}
                  {/* <a href={applicationLink} target="_blank" rel="noopener noreferrer"><button className="gradient-button">Apply</button></a> */}
                  <a href={applicationLink} target="_blank" rel="noopener noreferrer">Apply Now</a>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
}

export default Navibar;
